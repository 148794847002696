import { createContext, useContext, useState, useEffect } from "react";
import { doesSessionExist } from "supertokens-web-js/recipe/session";
import Snackbar from "@mui/material/Snackbar";
import { SnackBarType } from "hoc/withSnackbar";

interface AuthContextValue {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  showSnackbar: (params: {
    message: string;
    type?: SnackBarType;
    icon?: React.ReactNode;
  }) => void;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  showSnackbar: () => null,
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    async function checkAuth() {
      const isLoggedIn = await doesSessionExist();
      setIsAuthenticated(isLoggedIn);
    }
    checkAuth();
  }, []);

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const mapping = {
    info: {
      bgColor: "#583432",
      textColor: "#F0F0F0",
    },
    error: {
      bgColor: "#ed3a3a",
      textColor: "#F0F0F0",
    },
    success: {
      bgColor: "#387a2a",
      textColor: "#F0F0F0",
    },
    warning: {
      bgColor: "#f9b509",
      textColor: "#F0F0F0",
    },
  };

  const [type, setType] = useState<SnackBarType>("info");
  const [message, setMessage] = useState("");

  const showSnackbar = (params: {
    message: string;
    type?: SnackBarType;
    icon?: React.ReactNode;
  }) => {
    const { message, icon, type = "info" } = params;
    setMessage(message);
    setType(type);
    setOpenSnackbar(true);
  };

  const bgColor = mapping[type].bgColor;
  const textColor = mapping[type].textColor;

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, showSnackbar }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        className="w-full"
      >
        <div
          style={{ backgroundColor: bgColor }}
          className={`absolute bottom-28 w-fit rounded-lg px-4 py-2`}
        >
          <p
            style={{ color: textColor }}
            className={`w-fit text-center text-12px font-semibold`}
          >
            {message}
          </p>
        </div>
      </Snackbar>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
