import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { motion } from "framer-motion";

import "swiper/css";
import "swiper/css/effect-cards";

import TarotCard from "./TarotCard";

export type TarotCarouselProps = {
  onCardClick: (e: any) => void;
};

export default function TarotCarousel(props: TarotCarouselProps) {
  const { onCardClick = (e) => null } = props;
  return (
    <div className="w-full">
      {/* Desktop */}
      <div className="hidden w-full sm:block">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          spaceBetween={8}
          centeredSlides={true}
          watchSlidesProgress={true}
          slidesPerView={7}
          initialSlide={4}
        >
          {Array.from({ length: 19 }).map((_, index) => (
            <SwiperSlide>
              {({ isActive }) => {
                const swiper = useSwiper();
                const handleClick = (e) => {
                  if (!isActive) {
                    swiper?.slideTo?.(index);
                  }
                };
                return (
                  <div>
                    <TarotCard
                      onCardClick={isActive ? onCardClick : handleClick}
                      isActive={isActive}
                      key={`tarot-card-${index}`}
                    />
                  </div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* Mobile */}
      <div className="block w-full sm:hidden">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          spaceBetween={8}
          centeredSlides={true}
          watchSlidesProgress={true}
          slidesPerView={5}
          initialSlide={3}
        >
          {Array.from({ length: 19 }).map((_, index) => (
            <SwiperSlide>
              {({ isActive }) => {
                const swiper = useSwiper();
                const handleClick = isActive
                  ? undefined
                  : () => {
                      swiper?.slideTo?.(index);
                    };
                return (
                  <motion.div>
                    <TarotCard
                      onCardClick={isActive ? onCardClick : handleClick}
                      isActive={isActive}
                      key={`tarot-card-${index}`}
                    />
                  </motion.div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
