import { Product, SelectedTopping, SizeType } from "../models/Product";

export const formatCurrency = (
  amount: number,
  currency = "VND",
  locale = "it-IT",
) => {
  const formatter = new Intl.NumberFormat(locale, {
    currency: currency,
    style: "currency",
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

export const calculateItemTotalPrice = (
  item: Product,
  selectedSize: SizeType,
  selectedToppings: SelectedTopping[],
  isLogin: boolean = false,
) => {
  const { pricing } = item || {};

  const price = pricing?.find?.((item) => item.size === selectedSize);
  const { value = 0, member_value = 0 } = price || {};

  const selectedPrice = isLogin ? member_value : value;

  const toppingTotalPrice =
    selectedToppings?.reduce<number>((result, toppingItem) => {
      const { price = 0, member_price = 0 } = toppingItem || {};
      const selectedToppingPrice = isLogin ? member_price : price;
      return result + selectedToppingPrice;
    }, 0) || 0;

  return selectedPrice + toppingTotalPrice;
};
