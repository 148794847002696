import LessIce from "../../../assets/icons/ice/less-ice";
import NormalIce from "../../../assets/icons/ice/normal-ice";
import MoreIce from "../../../assets/icons/ice/more-ice";
import FullIceIcon from "assets/icons/ice/full-ice";
import NoIceIcon from "assets/icons/ice/no-ice";

const IceSelector = ({ value, checked, handleChange, label }) => {
  const sizeComponentMapping = {
    0: NoIceIcon,
    25: LessIce,
    50: NormalIce,
    75: MoreIce,
    100: FullIceIcon,
  };

  const SizeComponent = sizeComponentMapping?.[value] || null;

  return (
    <div
      key={value}
      onClick={() => {
        handleChange(value);
      }}
      className={`relative mr-1 flex w-fit flex-col items-center justify-end rounded-full px-3 py-3px`}
    >
      {SizeComponent && (
        <SizeComponent color={checked ? "#9F2B2B" : "#131313"} />
      )}
      <span
        className={`mt-2 text-[14px] ${checked ? `text-[#9F2B2B] underline` : "text-black"}`}
      >
        {label}
      </span>
    </div>
  );
};

export default IceSelector;
