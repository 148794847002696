import { useLanguage } from "../../hooks/useLanguage";
import dayjs from "dayjs";
import { BillItem, Charges, ProductItem } from "../../store/type";
import { formatCurrency } from "../../utils/ConcurencyUtils";

export type OrderInfoProps = {
  charges?: Charges;
};

export function PaymentInfo(props: OrderInfoProps) {
  const { t } = useLanguage();
  const { charges } = props;
  return (
    <div className={`rounded-lg bg-white py-2`}>
      <div className={`flex w-full flex-col justify-between px-4 py-2`}>
        <div className={`flex w-full flex-row justify-between`}>
          <p className={`text-14px font-normal text-brand-4`}>{t("inMoney")}</p>
          <p className={`text-14px font-bold text-brand-4`}>
            {formatCurrency(charges?.sub_total || 0)}
          </p>
        </div>
        <div className={`mt-1 flex w-full flex-row justify-between`}>
          <p className={`text-12px font-normal text-tertiary`}>
            {t("additionalCharge")}
          </p>
          <p
            className={`text-14px font-normal text-tertiary`}
          >{`${charges?.additional_charges}`}</p>
        </div>
        <div className={`mt-1 flex w-full flex-row justify-between`}>
          <p className={`text-12px font-normal text-tertiary`}>
            {t("discount")}
          </p>
          <p
            className={`text-14px font-normal text-tertiary`}
          >{`${charges?.discount}`}</p>
        </div>
        <div className={`mt-1 flex w-full flex-row justify-between`}>
          <p className={`text-12px font-normal text-tertiary`}>
            {t("payment")}
          </p>
          <p className={`text-14px font-normal text-tertiary`}>
            {formatCurrency(charges?.total || 0)}
          </p>
        </div>
      </div>
    </div>
  );
}
