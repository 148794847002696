import { Gap } from "components/gap/Gap";
import MyBillItem from "components/order/MyBillItem";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { useLanguage } from "hooks/useLanguage";
import { useEffect, useState } from "react";
import {
  useLazyQueryBillsQuery,
  useLazyQueryOrdersQuery,
} from "store/api/consumerApi";
import { BillItem } from "store/type";
import { formatCurrency } from "utils/ConcurencyUtils";

export type OrderHistoryPageProps = {};

export function OrderHistoryPage(props: OrderHistoryPageProps) {
  const { t } = useLanguage();
  const orderHistoryTabs = [
    {
      id: "paid",
      title: t("processing"),
      status: "paid",
    },
    {
      id: "Đã hoàn tất",
      title: t("fulfilled"),
      status: "fulfilled",
    },
    {
      id: "Đã huỷ",
      title: t("cancelled"),
      status: "cancelled",
    },
  ];
  const [getBills, { isSuccess, data: billsResult }] = useLazyQueryBillsQuery();
  const [getOrders, { isSuccess: isSuccessOrders, data: ordersResult }] =
    useLazyQueryOrdersQuery();
  const bills = billsResult?.result;
  const orders = ordersResult?.result?.orders;

  const orderWithBills = orders?.map((order) => {
    const bill = bills?.find((bill) => bill.order?.id === order.id);
    return {
      ...order,
      bill,
    };
  });

  const [activeTab, setActiveTab] = useState(orderHistoryTabs[0].id);

  const [ordersShouldShowFull, setOrdersShouldShowFull] = useState<number[]>(
    [],
  );

  useEffect(() => {
    getBills({});
    getOrders({
      status: orderHistoryTabs.find((tab) => tab.id === activeTab)?.status,
    });
  }, []);

  const onChangeTab = (tabId: string) => {
    setActiveTab(tabId);
    getOrders({
      status: orderHistoryTabs.find((tab) => tab.id === tabId)?.status,
    });
  };

  return (
    <div className="flex h-full w-full flex-col items-center bg-default-1">
      <PageHeader title={t("orderHistory")} />
      <Gap size={"S"} />

      <div className="h-full w-full max-w-screen-md bg-default-1 px-4">
        <div className="h-full w-full">
          <div className="flex flex-row justify-between rounded-[40px] bg-neutrals-divider px-[2px] py-[2px]">
            {orderHistoryTabs.map((tab) => {
              const isActive = tab.id === activeTab;
              return (
                <button
                  key={tab.id}
                  onClick={() => onChangeTab(tab.id)}
                  className={`relative w-1/3 rounded-[40px] px-0 py-2 transition-colors duration-300 ${
                    isActive
                      ? " border-[1px] border-solid border-gray-c5 bg-white"
                      : ""
                  }`}
                >
                  <span
                    className={`line-clamp-1 font-inter text-[14px] ${isActive ? "text-brand-4" : "font-normal text-gray-menu"}`}
                  >
                    {tab.title}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <Gap size={"S"} />
        <div className="min-h-screen w-full bg-default-1">
          {isSuccess &&
            orderWithBills?.map((orderBill) => (
              <div
                key={orderBill.id}
                className="mb-4 w-full rounded-lg bg-white px-2 py-2"
              >
                {orderBill?.bill?.items
                  ?.slice(
                    0,
                    orderBill?.id &&
                      ordersShouldShowFull.includes(orderBill?.id)
                      ? 100
                      : 2,
                  )
                  ?.map((item: BillItem, index: number) => (
                    <MyBillItem
                      key={index}
                      item={item}
                      showDivide={
                        index === 0 && (orderBill?.bill?.items?.length || 0) > 1
                      }
                    />
                  ))}
                <div className="flex flex-row justify-between pl-2 pt-2">
                  {(orderBill?.bill?.items?.length || 0) > 2 &&
                  orderBill?.id &&
                  !ordersShouldShowFull.includes(orderBill?.id) ? (
                    <span
                      className="text-brand-4 cursor-pointer"
                      onClick={() => {
                        if (orderBill?.id)
                          setOrdersShouldShowFull([
                            ...ordersShouldShowFull,
                            orderBill.id,
                          ]);
                      }}
                    >{`Xem thêm`}</span>
                  ) : (
                    <div />
                  )}
                  <div>
                    <span>{`Tổng số tiền (${orderBill?.bill?.items?.length || 0} món): `}</span>
                    <span className="font-semibold">
                      {formatCurrency(orderBill?.bill?.charges.total || 0)}
                    </span>
                  </div>
                </div>
                {/* <div className="flex flex-row justify-end gap-2 py-1 pt-2">
                  <button className="rounded-[40px] border-[1px] border-solid border-brand-4 px-4 py-1 text-brand-4">
                    Xem chi tiết
                  </button>
                  <button className="rounded-[40px] bg-brand px-4 py-1 text-white">
                    Đánh giá
                  </button>
                </div> */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default OrderHistoryPage;
