import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import Session from "supertokens-web-js/recipe/session";

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    // Lấy giá trị của cookie và tách bằng dấu phẩy
    const rawValue = parts.pop()?.split(";").shift() || null;

    if (rawValue) {
      // Loại bỏ giá trị trùng lặp
      const uniqueValues = Array.from(new Set(rawValue.split(",")));
      return uniqueValues[0] || null; // Trả về giá trị đầu tiên
    }
  }

  return null;
}

export const getGuestToken = () => localStorage.getItem("guestToken");

export const setGuestToken = (guestToken: string) => {
  console.log("Save GUEST_TOKEN");
  localStorage.setItem("guestToken", guestToken);
};

export const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("guestToken");
  document.cookie =
    "st-refresh-token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=None; Secure";
  document.cookie =
    "st-access-token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=None; Secure";
};

export const clearGuestToken = () => {
  localStorage.removeItem("guestToken");
};

const baseAxiosQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string;
  }): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async (axiosConfig) => {
    const instance = axios.create({
      baseURL: baseUrl,
    });

    // Add interceptor for request
    instance.interceptors.request.use(
      async (config) => {
        const token = getCookie("st-access-token");
        console.log("Will be use token:", token);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          const guestToken = getGuestToken();
          if (guestToken) {
            config.headers.Authorization = `Bearer ${guestToken}`;
          } else {
            const result = await axios.post(
              `${process.env.REACT_APP_CONSUMER_BASE_URL}/v1/get_anonymous_jwt`,
              {
                jsonrpc: "2.0",
                id: "0",
                method: "get_anonymous_jwt",
                params: {},
              },
            );
            const { result: guestToken } = result.data;
            setGuestToken(guestToken);
            config.headers.Authorization = `Bearer ${guestToken}`;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      async (response) => {
        if (response?.data?.error?.code === -32603) {
          // Something went wrong with current Cookie, should clear all tokens and cookies, and redirect to login
          // console.log(
          //   "Something went wrong with current Cookie, should clear all tokens and cookies, and redirect to login",
          // );
          // clearTokens();
          // window.location.href = "/login";
          return Promise.reject(
            new Error("Invalid token, redirecting to login."),
          );
        }
        if (response?.data?.error?.code === 7000) {
          // Unauthorized
          console.log("Unauthorized 7000");
          
          if (getCookie("st-refresh-token")) {
            try {
              const isRefreshTokenSuccess = await axios.post(
                `${process.env.REACT_APP_CONSUMER_BASE_URL}/auth/session/refresh`,
                {},
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getCookie("st-refresh-token")}`,
                  },
                },
              );
              if (isRefreshTokenSuccess) {
                console.log("Refresh Token Success, call api again...");
                const newToken = getCookie("st-access-token");
                response.config.headers.Authorization = `Bearer ${newToken}`;
                return instance.request(response.config);
              } else {
                console.log(
                  "Refresh Token FAILED, clear all tokens and cookies, and redirect to login",
                );
                clearTokens();
                // window.location.href = "/login";
              }
            } catch (refreshError) {
              console.log(
                "Something went wrong when refresh token, clear all tokens and cookies, and redirect to login",
                refreshError,
              );
              clearTokens();
              // window.location.href = "/login";
              return Promise.reject(refreshError);
            }
          } else {
            console.log(
              "st-refresh-token is EMPTY, clear all tokens and cookies, and redirect to login",
            );
            if (getCookie("st-access-token")) {
              // if login with access token, should redirect to login
              window.location.href = "/login";
            }
            clearTokens();
          }
        }

        return response;
      },
      (error) => {
        // Handle other Axios-level errors (e.g., network issues)
        console.error("Axios Error Interceptor:", error);
        return Promise.reject(error);
      },
    );

    const result = await instance(axiosConfig);
    console.log("Finale ~ result:", result);
    return { data: result.data };
  };

export default baseAxiosQuery;
