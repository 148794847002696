import ProductDetailBottomSheet from "components/bottom-sheet/ProductDetailBottomSheet";
import { Gap } from "components/gap/Gap";
import MenuProductItem from "components/menu/MenuProductItem";
import PageHeader from "components/PageHeader";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";
import { Product } from "models/Product";
import { useQueryFavoriteQuery } from "store/api/consumerApi";
import { useAppSelector } from "store/hook";
import { s3ImageUrl } from "utils/FileUtils";

type FavoritePageProps = {};

export default function FavoritePage(props: FavoritePageProps) {
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const favorites = useAppSelector((root) => root.allItems.favorites);
  const { t } = useLanguage();
  const favoriteItems = favorites
    .map((item) => {
      return allItems[item?.item_id];
    })
    .filter((item) => item);
  return (
    <div className="flex h-screen w-full flex-col items-center bg-default-1">
      <PageHeader title={t("favorite")} />
      <div className="h-screen w-full max-w-screen-md">
        {favoriteItems.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <Gap size={"L"} />
            <img
              src={s3ImageUrl("san_empty.png")}
              alt=""
              className="mx-auto w-1/2 md:w-1/3"
            />
            <Gap size={"M"} />
            <p className="text-14px font-semibold text-brand-4">
              {t("noFavorite")}
            </p>
            <Gap size={"XXS"} />
            <p className="text-center text-12px font-normal text-gray-menu">
              {t("emptyStateDescription")}
            </p>
          </div>
        )}
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
          {favoriteItems.length > 0 &&
            favoriteItems?.map((product, index) => (
              <MenuProductItem
                key={`${index}-product-item`}
                product={product as Product}
                index={index}
              />
            ))}
        </div>
      </div>
      <div className="z-50">
        <ProductDetailBottomSheet />
      </div>
    </div>
  );
}
