import { s3ImageUrl } from "utils/FileUtils";
import { useState, useEffect } from "react";
import ImageWithModal from "components/ImageWithModal";

export type TarotCardProps = {
  isActive?: boolean;
  onCardClick?: (e: any) => void;
  cardImage?: string;
  className?: string;
  imageClassName?: string;
  isUpright?: boolean;
};

export default function TarotCard({
  isActive,
  onCardClick,
  cardImage = "card4x.png",
  className = "",
  imageClassName = "",
  isUpright = true,
}: TarotCardProps) {
  const [marginBottom, setMarginBottom] = useState(0);

  useEffect(() => {
    if (isActive) {
      setMarginBottom(48);
    } else {
      setMarginBottom(0);
    }
  }, [isActive]);

  return (
    <div
      className={`flex h-[200px] ${isUpright ? "" : "rotate-180"} w-fit cursor-pointer flex-col items-center justify-center sm:h-[300px] ${className}`}
    >
      <ImageWithModal
        src={s3ImageUrl(cardImage)}
        containerClassName={className}
        alt=""
        onClick={onCardClick}
        isUpright={isUpright}
        className={`h-full w-fit rounded-[4px] object-cover transition-all duration-200 ease-in-out ${imageClassName}`}
        style={{ marginBottom }}
      />
    </div>
  );
}
