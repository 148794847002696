import { useNavigate } from "react-router-dom";
import IconBell from "../../assets/icons/icon-bell";
import IconSearch from "../../assets/icons/icon-search";
import { useLanguage } from "hooks/useLanguage";

type IconWrapperProps = {
  children: JSX.Element;
  onClick?: () => void;
};

const IconWrapper = (props: IconWrapperProps) => {
  return (
    <div
      className={`${props.onClick && "cursor-pointer"}
     flex h-10 w-10 flex-row items-center justify-center rounded-full bg-white pl-1 pt-1`}
      onClick={props?.onClick}
    >
      {props.children}
    </div>
  );
};

export default function HomeHeader() {
  const navigate = useNavigate();
  const { t } = useLanguage();
  return (
    <header className="flex w-full flex-row items-center justify-between px-4 pt-4">
      <span className={`text-14px font-semibold text-black`}>
        {t("hiThere")}
      </span>
      <div className="flex items-center justify-center gap-2">
        <IconWrapper>
          <IconSearch
            onClick={() => {
              navigate("/menu", {
                state: { isSearch: true },
              });
            }}
            className="h-6 w-6 md:w-6"
          />
        </IconWrapper>
      </div>
    </header>
  );
}
