import { formatCurrency } from "../../utils/ConcurencyUtils";
import ChevronRight from "../../assets/icons/chevron-right";
import { useLanguage } from "../../hooks/useLanguage";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useState } from "react";
import { Gap } from "components/gap/Gap";
import CloseIcon from "assets/icons/close";
import SanButton from "components/SanButton";
import { isEmpty } from "lodash";
import { useAppDispatch } from "store/hook";
import { applyVoucher } from "store/orderSlice";

export interface TotalPriceSectionProps {
  className?: string;
  total?: number;
  subTotal?: number;
  discount?: string;
  isFinal?: boolean; // Payment step => true | Order step => false
}

const Divide = () => <div className={`h-[1px] w-full bg-divide`} />;

export default function TotalPriceSection(props: TotalPriceSectionProps) {
  const {
    total = 0,
    subTotal = 0,
    discount = "",
    isFinal = false,
  } = props || {};
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const [activeVoucher, setActiveVoucher] = useState(discount);
  const [voucher, setVoucher] = useState("");

  const dispatch = useAppDispatch();

  const onDiscountClick = () => {
    setOpen(true);
  };

  return (
    <div
      className={`flex flex-col rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <div>
        <p className={`text-16px font-bold text-brand-4`}>
          {t("totalSection")}
        </p>
      </div>
      <div>
        <div className="flex flex-row justify-between py-2">
          <p className={`text-14px font-normal text-tertiary`}>
            {isFinal ? t("inMoney") : t("tempTotal")}
          </p>
          <p>{formatCurrency(subTotal)}</p>
        </div>
        <Divide />
        <div
          className={`flex flex-row justify-between ${isFinal ? "py-2" : "pt-2"}`}
          onClick={() => {
            !isFinal && onDiscountClick();
          }}
        >
          <p className={`text-14px font-normal text-tertiary`}>
            {t("discount")}
          </p>
          <div className={"flex flex-row items-center justify-between"}>
            {discount ||
              (!isEmpty(activeVoucher) && (
                <p className={`mr-3`}>{`${activeVoucher}`}</p>
              ))}
            {!isFinal && (
              <ChevronRight width="12px" height="12px" color="#9F2B2B" />
            )}
          </div>
        </div>
        {isFinal && <Divide />}
        {isFinal && (
          <div className="flex flex-row justify-between pt-2">
            <p className={`text-14px font-semibold text-brand-4`}>
              {t("totalNeedPay")}
            </p>
            <p className={`text-14px font-semibold text-brand-1`}>
              {formatCurrency(total)}
            </p>
          </div>
        )}
      </div>
      <BottomSheet
        open={open}
        expandOnContentDrag
        onDismiss={() => {
          setOpen(false);
        }}
      >
        <div className={`flex h-fit w-full flex-col items-center px-2`}>
          <div
            className="absolute right-6 top-6"
            onClick={() => setOpen(false)}
          >
            <CloseIcon width="12px" height="12px" />
          </div>
          <div className="w-full max-w-screen-md">
            <p className={`text-center text-16px font-bold text-brand-4`}>
              {t("discount")}
            </p>
            <Gap size={"M"} />
            <Gap size={"M"} />
            <div className="flex w-full flex-row justify-between gap-1.5">
              <input
                type="text"
                value={voucher}
                onChange={(e) => setVoucher(e.target.value)}
                className="h-[36px] w-4/5 rounded-[40px] bg-neutrals-divider px-4 text-14px font-normal text-brand-4 placeholder:text-tertiary focus:border-[1px] focus:border-solid focus:border-brand-1 focus:outline-none"
                placeholder={t("inputVoucherPlaceholder")}
              />
              <SanButton
                className="h-[36px] w-1/5 min-w-20 max-w-28"
                onClick={() => {
                  setActiveVoucher(voucher);
                  setOpen(false);
                  dispatch(applyVoucher(voucher));
                }}
                title={t("apply")}
              />
            </div>
            <Gap size={"M1"} />
          </div>
          {/* <div className={`w-full px-2 pb-4`}>
            <button
              className={`w-full rounded-[40px] bg-brand-1 px-8 py-[6px]`}
              onClick={() => {
                setOpen(false);
              }}
            >
              <p className={`text-14px font-semibold text-white`}>
                {t("agree")}
              </p>
            </button>
          </div> */}
        </div>
      </BottomSheet>
    </div>
  );
}
