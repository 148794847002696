import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { withSnackbar, WithSnackbarProps } from "hoc/withSnackbar";
import { useLanguage } from "hooks/useLanguage";
import { mockTarotResult } from "mocks/mockTarotResult";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { s3ImageUrl } from "utils/FileUtils";

export type TarotQuestionProps = {};

function TarotQuestion(props: TarotQuestionProps & WithSnackbarProps) {
  const { showSnackbar = () => null } = props;
  const { t } = useLanguage();
  const [context, setContext] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { topicType = "general" } = state || {};
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const mapTopicType = (topicType: string) => {
    switch (topicType) {
      case "general":
        return t("general");
      case "love":
        return t("love");
      case "career":
        return t("career");
      case "health":
        return t("health");
      default:
        return t("general");
    }
  };

  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  return (
    <div
      style={{ height: "100vh" }}
      className="flex flex-col items-center justify-center bg-[#fce8ce] font-inter"
    >
      <div className="flex h-full w-full max-w-screen-md flex-col items-center">
        <PageHeader
          title={mapTopicType(topicType)}
          transparent
          backColor="#31261D"
        />
        {/* <Gap size="L" /> */}
        <div className="w-full px-10">
          <h1 className="mb-2 text-center font-inter text-[24px] font-semibold leading-[32px] text-brand-4 md:text-[48px] md:leading-[56px]">
            {t("tarotQuestion")}
          </h1>
          <p className="px-4 text-center font-inter text-[14px] font-normal leading-[20px] text-brand-31 md:text-[18px] md:leading-[32px]">
            {t("questionDescription")}
          </p>
        </div>
        <Gap size="S" />
        <div className="flex h-fit w-full flex-col items-center px-4">
          <textarea
            onChange={(e) => setContext(e.target.value)}
            value={context}
            style={{
              WebkitTextSizeAdjust: "120%",
            }}
            ref={textAreaRef}
            maxLength={250}
            className={`max-h-[160px] min-h-[120px] w-full resize-none rounded-[28px] border-[1px] border-solid border-brand-31 bg-transparent px-2 py-2 text-center text-14px text-brand-31
               placeholder-gray-menu scrollbar-hide focus:outline-none focus:ring-0 md:text-[18px]`}
            placeholder={t("questionPlaceholder")}
          />
        </div>
        <Gap size="S" />
        <div className="w-full max-w-screen-md flex px-4">
          <SanButton
            className="border-[1px] border-brand-31 bg-transparent"
            title={t("selectCard").replace("{numOfCards}", "")}
            type="outline"
            loading={isLoading}
            transparentBackground
            textClassName="text-brand-31"
            onClick={async () => {
              try {
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                  navigate("/tarot-experience", {
                    state: {
                      topicType: topicType,
                      context: context,
                    },
                  });
                });
              } catch (error) {
                setIsLoading(false);
                showSnackbar({
                  message: t("somethingWentWrong"),
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(TarotQuestion);
