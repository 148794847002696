import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStartAnonymousExperienceMutation } from "store/api/consumerApi";
import { useAppDispatch, useAppSelector } from "store/hook";
import { setSessionId } from "store/sessionSlice";
import { s3ImageUrl } from "utils/FileUtils";
import { v4 as uuidv4 } from "uuid";

export type TarotErrorProps = {};

export default function TarotError(props: TarotErrorProps) {
  const navigate = useNavigate();
  const sessionId = useAppSelector((root) => root.session.session_id);
  const { t } = useLanguage();
  const [startAnonymousExperience, { isLoading }] =
    useStartAnonymousExperienceMutation();
  const { isAuthenticated: isLogin } = useAuthContext();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const sessionId = uuidv4();
    dispatch(setSessionId(sessionId));
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-center overflow-hidden bg-[#210909]">
      <div className="flex h-full w-full max-w-screen-md flex-col">
        <PageHeader transparent backColor="#fff" />
        <Gap size="L" />
        <div className="px-4 pr-20">
          <h1 className="mb-4 font-space text-[48px] font-bold leading-[56px] text-white">
            {t("tarotWelcome")}
          </h1>
        </div>
      </div>
      <div className="flex w-full max-w-screen-md flex-col items-center justify-center px-4 pb-6">
        <div
          className="relative w-full"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            className="absolute -bottom-6 right-0 z-10 w-5/6 max-w-[400px]"
            src={s3ImageUrl("san_tarot_girl.webp")}
            alt=""
          />
          <img
            className="absolute bottom-4 right-0 w-5/6 max-w-[400px]"
            src={s3ImageUrl("san_girl_bg.png")}
            alt=""
          />
          <img
            className="absolute -right-36 bottom-72 z-30 h-20"
            src={s3ImageUrl("cloud.png")}
            alt=""
          />
          <img
            className="absolute bottom-60 right-6 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute -left-36 bottom-16 z-30 h-20"
            src={s3ImageUrl("cloud.png")}
            alt=""
          />
          <img
            className="absolute bottom-32 left-6 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
        </div>
        <SanButton
          className="bg-white"
          type="outline"
          loading={isLoading}
          title={t("startTarot")}
          onClick={async () => {
            if (isLogin) {
              navigate("/tarot-select-topic");
            } else {
              console.log("🚀 ~ lamnn ~ onClick={ ~ sessionId:", sessionId);
              const res = await startAnonymousExperience({
                session_id: sessionId || "",
              }).unwrap();
              console.log("🚀 ~ lamnn ~ onClick={ ~ res:", res);
              // navigate("/tarot-experience");
            }
          }}
        />
        <Gap size="S" />
        <a href="/">
          <span className="text-center text-[14px] font-semibold text-white">
            {t("backToOrder")}
          </span>
        </a>
      </div>
    </div>
  );
}
