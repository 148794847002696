import { Gap } from "../../components/gap/Gap";
import PageHeader from "../../components/PageHeader";
import { useLanguage } from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { StoreInfo } from "./StoreInfo";
import { OrderInfo } from "./OrderInfo";
import { useAppSelector } from "../../store/hook";
import dayjs from "dayjs";
import { PaymentInfo } from "./PaymentInfo";

interface BillDetailPageProps {}

export default function BillDetailPage(props: BillDetailPageProps) {
  const { t } = useLanguage();
  const bill = useAppSelector((root) => root.bill.bill);
  const navigate = useNavigate();
  return (
    <div className={`flex h-screen w-full flex-col bg-default-1`}>
      <PageHeader title={t("billTitle")} />
      <div className="h-auto w-full max-w-screen-md p-4">
        <p className={`text-18px font-bold text-brand-4`}>{t("paymentBill")}</p>
        <div className={`flex flex-row gap-1`}>
          <p className={`text-14px font-normal text-gray-menu`}>
            {`${t("number")}: `}
          </p>
          <span>{bill?.id}</span>
        </div>
        <div className={`flex flex-row gap-1`}>
          <p className={`text-14px font-normal text-gray-menu`}>
            {`${t("date")}: `}
          </p>
          <span>{dayjs(bill?.order?.date).format("DD/MM/YYYY")}</span>
        </div>

        <Gap size={"M"} />
        {/* <StoreInfo
          inTime={Date.now()}
          outTime={Date.now()}
          cashier={"Tên thu ngân"}
          clientType={"Khách lẻ"}
        /> */}
        <OrderInfo items={bill?.items} />
        <Gap size={"S"} />
        <PaymentInfo charges={bill?.charges} />
      </div>
    </div>
  );
}
