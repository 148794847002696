import { useLanguage } from "../../hooks/useLanguage";
import dayjs from "dayjs";
import { BillItem, ProductItem } from "../../store/type";
import { formatCurrency } from "../../utils/ConcurencyUtils";

export type OrderInfoProps = {
  items?: BillItem[];
};

export function OrderInfo(props: OrderInfoProps) {
  const { t } = useLanguage();
  const { items = [] } = props;
  return (
    <div className={`rounded-lg bg-white py-2`}>
      {items?.map((item, index) => (
        <div
          key={`${index}-${item.item_id}-store-info`}
          className={`flex flex-col px-4`}
        >
          <div className={`flex w-full flex-col justify-between py-2`}>
            <div className={`flex w-full flex-row justify-between`}>
              <p className={`text-14px font-normal text-brand-4`}>
                {item.item_name}
              </p>
              <p className={`text-14px font-bold text-brand-4`}>
                {formatCurrency(item.price)}
              </p>
            </div>
            <div className={`mt-1 flex w-full flex-row justify-between`}>
              <p className={`text-12px font-normal text-tertiary`}>
                {t("quantity")}
              </p>
              <p
                className={`text-14px font-normal text-tertiary`}
              >{`x${item.quantity}`}</p>
            </div>
            <div className={`mt-1 flex w-full flex-row justify-between`}>
              <p className={`text-12px font-normal text-tertiary`}>
                {t("price")}
              </p>
              <p className={`text-14px font-normal text-tertiary`}>
                {formatCurrency(item.price * item.quantity)}
              </p>
            </div>
          </div>
          {index < items?.length - 1 && (
            <div className={`h-[1px] w-full bg-divide`} />
          )}
        </div>
      ))}
    </div>
  );
}
