import React from "react";
import { Gap } from "../../components/gap/Gap";
import { useLanguage } from "hooks/useLanguage";
import PageHeader from "components/PageHeader";
import Markdown from "react-markdown";
import { s3ImageUrl } from "utils/FileUtils";

const markdown = `

## 1. Mục đích và phạm vi thu thập thông tin

Sắn Cafe cam kết không bán, chia sẻ hay trao đổi thông tin cá nhân của khách hàng thu thập từ website cho bất kỳ bên thứ ba nào.

Thông tin cá nhân thu thập được sẽ chỉ được sử dụng trong nội bộ công ty và nhằm mục đích cải thiện chất lượng dịch vụ.

Khi khách hàng liên hệ đặt dịch vụ hoặc sản phẩm, Sắn Cafe có thể thu thập các thông tin cá nhân sau:

- **Họ và tên**
- **Địa chỉ**
- **Số điện thoại**
- **Email**

Ngoài ra, thông tin về dịch vụ hoặc sản phẩm như:

- **Tên sản phẩm/dịch vụ**
- **Số lượng**
- **Thời gian giao nhận**

## 2. Phạm vi sử dụng thông tin

Thông tin cá nhân của khách hàng sẽ chỉ được Sắn Cafe sử dụng trong các trường hợp sau:

- Hỗ trợ khách hàng.
- Cung cấp thông tin liên quan đến dịch vụ hoặc sản phẩm.
- Xử lý đơn đặt hàng và cung cấp sản phẩm, dịch vụ theo yêu cầu.
- Gửi thông tin về sản phẩm, dịch vụ mới, các chương trình ưu đãi hoặc sự kiện nếu khách hàng đăng ký nhận thông báo qua email.
- Hỗ trợ quản lý tài khoản khách hàng.
- Xác nhận và thực hiện các giao dịch tài chính liên quan đến thanh toán trực tuyến.

## 3. Thời gian lưu trữ thông tin

Thông tin cá nhân của khách hàng sẽ được lưu trữ cho đến khi có yêu cầu xóa từ phía khách hàng. Mọi yêu cầu xóa thông tin vui lòng liên hệ qua email: **contact@san.cafe**.

## 4. Đối tượng tiếp cận thông tin cá nhân

Thông tin cá nhân của khách hàng chỉ được cung cấp cho các đối tượng sau:

- **Công ty TNHH Sắn Cafe**.
- Các đối tác có hợp đồng thực hiện một phần dịch vụ cho Sắn Cafe. Thông tin cung cấp sẽ dựa trên thỏa thuận hợp đồng, đảm bảo chỉ sử dụng cho mục đích hỗ trợ dịch vụ.

## 5. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân

- **Công ty TNHH Sắn Cafe**  

- **Địa chỉ:** Căn SH36, Khu nhà ở Rue de Charme, Dự án Khu đô thị mới Hạ Đình, Ngõ 214
Nguyễn Xiển, Xã Tân Triều, Huyện Thanh Trì, Thành phố Hà Nội, Việt Nam

- **Email:** contact@san.cafe

- **Website:** [san.cafe](https://san.cafe)

## 6. Phương tiện và công cụ để khách hàng tiếp cận và chỉnh sửa thông tin cá nhân

Sắn Cafe không thu thập thông tin cá nhân trực tiếp trên website. Thông tin cá nhân được thu thập qua email hoặc số điện thoại liên hệ khi đặt sản phẩm/dịch vụ:

Khách hàng có thể liên hệ qua các phương tiện trên để yêu cầu chỉnh sửa hoặc cập nhật thông tin cá nhân.

## 7. Cơ chế tiếp nhận và giải quyết khiếu nại

Sắn Cafe cam kết bảo mật thông tin cá nhân của khách hàng và chỉ sử dụng thông tin cho các mục đích đã thông báo. Trong trường hợp có khiếu nại về việc sử dụng sai mục đích hoặc phạm vi, khách hàng vui lòng liên hệ qua:

- **Email:** contact@san.cafe

Chúng tôi cam kết:

- Nâng cao chất lượng dịch vụ dành cho khách hàng.
- Giải quyết các tranh chấp, khiếu nại một cách nhanh chóng.
- Tuân thủ yêu cầu từ cơ quan pháp luật khi cần thiết.

Nếu có bất kỳ thắc mắc hoặc ý kiến đóng góp nào liên quan đến chính sách bảo mật, xin vui lòng liên hệ qua email hoặc hotline trên.

`;

const SecurePolicyPage: React.FC = () => {
  const { t } = useLanguage();
  return (
    <div
      className={`flex h-full w-full flex-col items-center pb-20 sm:h-screen`}
    >
      <div
        style={{ height: "calc(100% - 64px)" }}
        className="relative h-full w-full max-w-screen-md overflow-visible"
      >
        <img
          src={s3ImageUrl("bg_san_no_logo.webp")}
          alt=""
          className={`z-0 h-full w-full`}
        />
        <div className="absolute bottom-0 left-0 right-0 top-0 z-0 min-h-[320%] sm:min-h-[70%]">
          <PageHeader transparent title={t("securePolicy")} />
          <div
            style={{ height: "calc(100% - 64px)" }}
            className="mx-4 mt-20 flex h-full flex-col items-center rounded-t-2xl bg-white p-4 md:mx-8"
          >
            <Gap size={"S"} />
            <img
              src={s3ImageUrl("san-logo.png")}
              alt=""
              className="h-12 w-12"
            />
            <Markdown
              components={{
                h1(props) {
                  const { node, ...rest } = props;
                  return (
                    <h1 className={`text-3xl my-3 text-brand-4`} {...rest} />
                  );
                },
                h2(props) {
                  const { node, ...rest } = props;
                  return (
                    <h2 className={`my-1 text-18px text-brand-4`} {...rest} />
                  );
                },
                h3(props) {
                  const { node, ...rest } = props;
                  return (
                    <h3 className={`text-lg my-2 text-brand-4`} {...rest} />
                  );
                },
                ul(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                p(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                a(props) {
                  const { node, ...rest } = props;
                  return props.href?.includes("@") ? (
                    <a
                      href={`mailto:${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  ) : (
                    <a
                      href={`${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  );
                },
              }}
              className={`mt-6 text-brand-4 xl:mt-8`}
            >
              {markdown}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurePolicyPage;
