import { useLanguage } from "hooks/useLanguage";
import { useEffect, useState } from "react";
import { s3ImageUrl } from "utils/FileUtils";

function TarotGuide() {
  const { t } = useLanguage();
  const [image, setImage] = useState("swipe_anim.gif");
  const [content, setContent] = useState(t("swipeToMove"));
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);

      setTimeout(() => {
        setImage((prev) => (prev === "swipe_anim.gif" ? "tap_anim.gif" : "swipe_anim.gif"));
        setContent((prev) =>
          prev === t("swipeToMove") ? t("tapToSelect") : t("swipeToMove")
        );

        setIsFading(false);
      }, 500);
    }, 4000);

    return () => clearInterval(interval);
  }, [t]);

  return (
    <div className="flex w-full flex-col items-center -mt-5">
      <img
        src={s3ImageUrl(image)}
        className={`h-12 w-14 object-cover transition-opacity duration-500 ${
          isFading ? "opacity-0" : "opacity-100"
        }`}
        alt=""
      />
      <span
        className={`-mt-1 font-inter text-12px font-normal text-brand-4 md:text-[18px] transition-opacity duration-500 ${
          isFading ? "opacity-0" : "opacity-100"
        }`}
      >
        {content}
      </span>
    </div>
  );
}

export default TarotGuide;
