import axios from "axios";
import { setGuestToken } from "store/api/baseAxiosQuery";

export const loginAsGuest = async () => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_CONSUMER_BASE_URL}/v1/get_anonymous_jwt`,
      {
        jsonrpc: "2.0",
        id: "0",
        method: "get_anonymous_jwt",
        params: {},
      },
    );
    const { result: guestToken } = result.data;
    setGuestToken(guestToken);
  } catch (error) {
    console.log("~ login error:", error);
  }
};
