import React from "react";
import { Gap } from "../../components/gap/Gap";
import { useLanguage } from "hooks/useLanguage";
import PageHeader from "components/PageHeader";
import Markdown from "react-markdown";
import { s3ImageUrl } from "utils/FileUtils";

const markdown = `
## HƯỚNG DẪN THANH TOÁN VNPAY TRÊN WEBSITE

Cổng thanh toán VNPAY là giải pháp thanh toán do Công ty Cổ phần Giải pháp Thanh toán Việt Nam (VNPAY) phát triển. Khách hàng có thể sử dụng thẻ/tài khoản ngân hàng, tính năng QR Pay/VNPAY-QR được tích hợp sẵn trên ứng dụng Mobile Banking của các ngân hàng hoặc các Ví điện tử liên kết để thanh toán giao dịch và nhập mã giảm giá (nếu có).

---
*Quét mã VNPAY-QR trên hơn 35+ ứng dụng Mobile Banking và 15+ Ví điện tử liên kết*
![Quét mã VNPAY-QR](https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com/ui/vnpay-1.png)  


*40+ Thẻ ATM/nội địa/tài khoản ngân hàng* 
![Quét mã VNPAY-QR](https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com/ui/vnpay-2.png)  

*4 Thẻ thanh toán quốc tế* 
![Quét mã VNPAY-QR](https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com/ui/vnpay-3.png)  

---

## Các phương thức thanh toán qua VNPAY

![Quét mã VNPAY-QR](https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com/ui/vnpay-4.png)  


### 1. Phương thức thanh toán qua “Ứng dụng thanh toán hỗ trợ VNPAY-QR”

#### **Bước 1:** 
 Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng 
Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh toán VNPAY và nhấn nút “Đặt hàng ngay”.


#### **Bước 2:**
Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY. Chọn phương thức  “Ứng dụng thanh toán hỗ trợ VNPAY-QR”

#### **Bước 3:** 
Hệ thống hiển thị mã QR cùng với số tiền cần thanh toán, Quý khách kiểm tra lại số tiền này. Sử dụng ứng dụng ngân hàng (theo danh sách liệt kê), chọn “Quét Mã” và tiến hành quét mã QR trên màn hình thanh toán website
*Lưu ý: Mã QR có hiệu lực trong 15 phút 
Để quá trình thanh toán thành công, khách hàng vui lòng tham khảo trước các điều kiện và thao tác quét mã trên điện thoại để sẵn sàng, tránh sự cố hết thời gian ảnh hưởng đến thanh toán và mã khuyến mại của quý khách.

#### **Bước 4:** 
Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất thanh toán
Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo xác nhận đơn hàng đặt hàng thành công tại website

![Mã QR thanh toán VNPAY](https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com/ui/vnpay-5.png)  
*Hình ảnh minh họa mã QR*

---

### 2. Phương thức thanh toán qua “Thẻ nội địa và tài khoản ngân hàng”

#### **Bước 1:** 
Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng 
Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh toán VNPAY và nhấn nút “Đặt hàng ngay”.

#### **Bước 2:** 
Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY. Chọn phương thức  “Thẻ nội địa và tài khoản ngân hàng” và chọn ngân hàng muốn thanh toán thẻ trong danh sách 


#### **Bước 3:** 
Quý khách vui lòng thực hiện nhập các thông tin thẻ/tài khoản theo yêu cầu và chọn “Tiếp tục”. Mã OTP sẽ được gửi về điện thoại đăng ký, nhập mã OTP để hoàn tất giao dịch 
*Lưu ý: Giao dịch sẽ hết hạn sau 15 phút 

#### **Bước 4:** 
Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo xác nhận đơn hàng đặt hàng thành công tại website

![Mã QR thanh toán VNPAY](https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com/ui/vnpay-6.png)  

---

### 3. Phương thức thanh toán qua “Thẻ thanh toán quốc tế”
- Các bước thực hiện tương tự như thanh toán qua **"Thẻ nội địa và tài khoản ngân hàng"**.

---

### 4. Phương thức thanh toán qua “Ví điện tử VNPAY”
- Các bước thực hiện tương tự như thanh toán qua **"Ứng dụng thanh toán hỗ trợ VNPAY-QR"**.

---

## KÊNH HỖ TRỢ VNPAY
- **Tổng đài:** *3388 hoặc 1900 55 55 77  
- **Zalo OA:** [zalo.me/4134983655549474109](https://zalo.me/4134983655549474109)  
- **Email:** hotro@vnpay.vn  
- **Fanpage:** [facebook.com/VNPAYQR.vn](https://facebook.com/VNPAYQR.vn)
`;

const VnpayGuide: React.FC = () => {
  const { t } = useLanguage();
  return (
    <div
      className={`flex h-full w-full flex-col items-center pb-20 sm:h-screen`}
    >
      <div className="relative h-full w-full max-w-screen-md overflow-visible">
        <PageHeader transparent title={t("vnpayGuide")} />
        <div className="mx-4 flex h-full flex-col items-center bg-white">
          <Markdown
            components={{
              h1(props) {
                const { node, ...rest } = props;
                return (
                  <h1 className={`text-3xl my-3 text-brand-4`} {...rest} />
                );
              },
              h2(props) {
                const { node, ...rest } = props;
                return (
                  <h2 className={`my-1 text-18px text-brand-4`} {...rest} />
                );
              },
              h3(props) {
                const { node, ...rest } = props;
                return <h3 className={`text-lg my-2 text-brand-4`} {...rest} />;
              },
              ul(props) {
                const { node, ...rest } = props;
                return (
                  <span
                    style={{ color: "#31261D" }}
                    className="text-justify text-[14px]"
                    {...rest}
                  />
                );
              },
              p(props) {
                const { node, ...rest } = props;
                return (
                  <span
                    style={{ color: "#31261D" }}
                    className="text-justify text-[14px]"
                    {...rest}
                  />
                );
              },
              a(props) {
                const { node, ...rest } = props;
                return props.href?.includes("@") ? (
                  <a
                    href={`mailto:${props.href}`}
                    style={{ color: "deepskyblue" }}
                    {...rest}
                  />
                ) : (
                  <a
                    href={`${props.href}`}
                    style={{ color: "deepskyblue" }}
                    {...rest}
                  />
                );
              },
            }}
            className={`mt-6 text-brand-4 xl:mt-8`}
          >
            {markdown}
          </Markdown>
        </div>
      </div>
    </div>
  );
};

export default VnpayGuide;
