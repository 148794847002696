import {
  calculateItemTotalPrice,
  formatCurrency,
} from "../../utils/ConcurencyUtils";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { Order } from "../../store/type";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import ChevronRight from "../../assets/icons/chevron-right";
import { useLanguage } from "../../hooks/useLanguage";
import { closeBottomSheet } from "../../store/bottomSheetSlice";
import { useState } from "react";

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { s3ImageUrl } from "../../utils/FileUtils";
import { Gap } from "../gap/Gap";
import IconRadio from "assets/icons/radio-icon";
import { FLAGS, PAYMENT_METHOD } from "helpers/constants";
import { setPaymentMethod } from "store/orderSlice";

export interface PaymentMethodSectionProps {
  className?: string;
  shouldShowSelector?: boolean;
}

export default function PaymentMethodSection(props: PaymentMethodSectionProps) {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const paymentMethod = useAppSelector((root) => root.order.paymentMethod);
  const dispatch = useAppDispatch();
  const { shouldShowSelector = true } = props;

  const paymentMethods = [
    FLAGS.ENABLE_VNPAY && {
      id: 1,
      name: "VNPay",
      logo: "san_vnpay.png",
      type: PAYMENT_METHOD.VNPAY,
    },
    {
      id: 2,
      name: t("cash"),
      logo: "san_cash.png",
      type: PAYMENT_METHOD.CASH,
    },
  ];

  return (
    <div
      className={`flex flex-col rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <div>
        <p className={`text-16px font-bold text-brand-4`}>
          {t("paymentMethod")}
        </p>
      </div>
      <Gap size={"XS"} />
      {paymentMethods.map((item, index) => {
        if (!shouldShowSelector && item && paymentMethod !== item.type) {
          return null;
        }
        return (
          item && (
            <div
              key={index}
              className={`flex flex-row items-center justify-between py-2`}
              onClick={() => {
                dispatch(setPaymentMethod(item.type));
              }}
            >
              <div className="flex flex-row items-center">
                <img
                  className={`h-8 w-8 rounded-full`}
                  src={s3ImageUrl(item.logo)}
                  alt=""
                />
                <p className={`ml-2 text-14px font-normal text-gray-menu`}>
                  {item.name}
                </p>
              </div>
              {shouldShowSelector && (
                <IconRadio $isActive={paymentMethod === item.type} />
              )}
            </div>
          )
        );
      })}
    </div>
  );
}
