import ChevronLeft from "../assets/icons/chevron-left";
import { useNavigate } from "react-router-dom";

type PageHeaderProps = {
  title?: string;
  IconRight?: React.FC<{ className?: string; color?: string }>;
  onIconRightClick?: () => void;
  showBackButton?: boolean;
  transparent?: boolean;
  titleColor?: string;
  backColor?: string;
  IconLeft?: React.FC<{ className?: string; color?: string }>;
};

export default function PageHeader(props: PageHeaderProps) {
  const navigate = useNavigate();
  const {
    IconRight,
    onIconRightClick,
    showBackButton = true,
    backColor = "#31261D",
    IconLeft,
  } = props;

  const renderLeftComponent = IconLeft ? (
    <IconLeft />
  ) : (
    <div
      onClick={() => {
        navigate(-1);
      }}
      className="flex h-9 w-9 cursor-pointer items-center justify-center"
    >
      <ChevronLeft color={backColor} />
    </div>
  );

  return (
    <header
      className={`left-0 right-0 top-0 z-50 flex min-h-[58px] w-screen max-w-screen-md flex-row items-center justify-between md:h-[70px] ${props.transparent ? "bg-transparent" : "bg-white"} px-4`}
    >
      {showBackButton ? renderLeftComponent : <div className="h-9 w-9" />}
      {props?.title && (
        <span
          className={`text-[16px] md:text-[24px] font-bold text-brand-4`}
          style={{ color: props.titleColor }}
        >
          {props?.title}
        </span>
      )}
      <div onClick={onIconRightClick} className={`flex h-9 w-9 items-center justify-center cursor-pointer`}>
        {IconRight && <IconRight />}
      </div>
    </header>
  );
}
