import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import BottomTabBar from "./components/BottomTabbar";
import PageHeader from "./components/PageHeader";
import IconHome from "./assets/icons/icon-home";
import IconCoffee from "./assets/icons/icon-coffee";
import IconCart from "./assets/icons/icon-cart";
import IconProfile from "./assets/icons/icon-profile";
import ProductDetailBottomSheet from "components/bottom-sheet/ProductDetailBottomSheet";
import { useLanguage } from "hooks/useLanguage";

const MainLayout: React.FC = () => {
  const { t } = useLanguage();
  const menus = [
    {
      id: 1,
      href: "/",
      title: t("home"),
      showHeader: false,
      icon: IconHome,
      showBadge: false,
      showBackButton: false,
    },
    {
      id: 2,
      href: "/menu",
      title: "Menu",
      showHeader: false,
      icon: IconCoffee,
      showBadge: false,
      showBackButton: false,
    },
    {
      id: 3,
      href: "/orders",
      title: t("orders"),
      showHeader: true,
      icon: IconCart,
      showBadge: true,
      showBackButton: false,
    },
    {
      id: 4,
      href: "/profile",
      title: t("profile"),
      showHeader: false,
      icon: IconProfile,
      showBadge: false,
      showBackButton: false,
      transparent: true,
      titleColor: "#fff",
    },
  ];

  const { pathname } = useLocation();

  const activeMenu = menus.find((menu) => menu.href === pathname);

  return (
    <div className="flex min-h-screen w-screen max-w-screen-md flex-col bg-default-1">
      {activeMenu?.showHeader ? (
        <PageHeader
          titleColor={activeMenu?.titleColor}
          transparent={activeMenu?.transparent}
          showBackButton={activeMenu?.showBackButton}
          title={activeMenu?.title || ""}
        />
      ) : null}
      <div className="flex-grow">
        <Outlet />
        <div className="z-50">
          <ProductDetailBottomSheet />
        </div>
      </div>
      <BottomTabBar menus={menus} />
    </div>
  );
};

export default MainLayout;
