import { formatCurrency } from "../../utils/ConcurencyUtils";
import { useAppSelector } from "../../store/hook";
import { BillItem } from "store/type";
import { generateCustomizeDescription } from "utils/OrderUtils";
import { useMenuData } from "hooks/useMenuData";
import { useLanguage } from "hooks/useLanguage";

export interface OrderItemProps {
  item: BillItem;
  showDivide: boolean;
}

export default function MyBillItem(props: OrderItemProps) {
  const { item, showDivide } = props || {};
  const {} = useMenuData();
  const { t } = useLanguage();
  const { price, quantity, item_id, item_name } = item || {};
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const itemInMenu = allItems?.[item_id];
  const isOneSize = itemInMenu?.pricing?.length === 1;
  const description = generateCustomizeDescription(item, isOneSize, t);

  return (
    <div className="mt-2 w-full">
      <div className={`flex w-full flex-row items-center justify-between`}>
        <img
          src={itemInMenu?.image || ""}
          alt=""
          className={`h-14 w-14 min-w-14 rounded-lg`}
        />
        <div
          className={`-mt-2 ml-2 flex min-h-14 w-full flex-col items-start justify-start`}
        >
          <div className="flex w-auto flex-row justify-between gap-2">
            <p className={`line-clamp-1 text-14px font-normal text-brand-4`}>
              {item_name}
            </p>
          </div>
          <p className={`text-12px font-normal text-tertiary`}>{description}</p>
          <p className={`text-12px font-normal text-brand-1`}>
            {/* {formatCurrency(price)} */}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-14px font-normal text-tertiary">{`${formatCurrency(price)}`}</p>
          <p className="text-12px font-normal text-tertiary">{`x${quantity}`}</p>
          <p className="text-12px font-normal text-brand-1">{`${formatCurrency(price * quantity)}`}</p>
        </div>
      </div>
      {showDivide && <div className={`mt-2 h-[1px] w-full bg-[#FAFAFA]`} />}
    </div>
  );
}
