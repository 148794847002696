import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { DetailedHTMLProps, ImgHTMLAttributes, useState } from "react";
import ModalClose from "@mui/joy/ModalClose";
import { Gap } from "./gap/Gap";
type ImageWithModalProps = {
  src: string;
  alt: string;
  className?: string;
  onClick?: (e: any) => void;
  containerClassName?: string;
  isUpright?: boolean;
  description?: string;
  cardDescription?: string;
};

const ImageWithModal = ({
  src,
  alt,
  className,
  containerClassName,
  onClick,
  isUpright = true,
  description = "",
  cardDescription,
  ...props
}: ImageWithModalProps &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const [showModal, setShowModal] = useState(false);
  const [isRotated, setIsRotated] = useState(isUpright);
  const onClickToShowModal = () => {
    setShowModal(true);
  };
  return (
    <div className={containerClassName} {...props}>
      <img
        onClick={onClick ? onClick : onClickToShowModal}
        src={src}
        alt={alt}
        className={className}
      />
      {cardDescription && <Gap size="XXS" />}

      {cardDescription && (
        <span className="font-space text-14px font-semibold text-brand-4 md:text-[24px]">
          {cardDescription}
        </span>
      )}
      <Modal
        onClose={() => {
          setShowModal(false);
          setIsRotated(isUpright);
        }}
        open={showModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalDialog
          sx={{ p: 2, borderRadius: 12, background: "rgba(0, 0, 0, 0.2)" }}
          variant={"plain"}
        >
          <div className="flex w-[300px] flex-col items-center justify-center bg-transparent sm:w-[450px]">
            <img
              src={src}
              alt={alt}
              className={`w-[300px] rounded-lg sm:w-[450px] ${isRotated ? "" : "rotate-180"} transition-transform duration-500 `}
            />
            {description && (
              <span className="mt-4 bg-transparent text-center text-16px font-semibold text-white sm:text-18px">
                {description}
              </span>
            )}
            {/* <span
              onClick={() => {
                setIsRotated(!isRotated);
              }}
              className="my-2 bg-transparent text-center text-16px font-semibold text-brand-shape-3 sm:text-18px"
            >
              Xoay bài
            </span> */}
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default ImageWithModal;
