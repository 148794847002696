import { Product } from "../../models/Product";
import ChevronRight from "../../assets/icons/chevron-right";
import MenuProductItem from "./MenuProductItem";
import { useLanguage } from "hooks/useLanguage";

type MenuWithCategoryProps = {
  categoryName: string;
  products: Product[];
};

export function MenuWithCategory(props: MenuWithCategoryProps) {
  const { categoryName, products } = props;
  const { language } = useLanguage();
  return (
    <div className="w-full">
      <div className={`flex w-full flex-row justify-between px-4`}>
        <span className={`text-16px font-bold text-brand-4`}>
          {categoryName}
        </span>
        {/* {
        products.length > 5 &&
        <div className="flex flex-row items-center gap-2">
          <span>{`Xem thêm (${products.length - 5})`}</span>
          <ChevronRight />
        </div>
      } */}
      </div>
      <div className="flex flex-row gap-2 overflow-scroll pr-4 scrollbar-hide">
        {products
          .filter((product) => product?.public === true)
          .map((product, index) => (
            <MenuProductItem
              key={`${index}-product-item`}
              product={product}
              index={index}
            />
          ))}
      </div>
    </div>
  );
}
