import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

type Card = {
  id: number;
  title: string;
};

const cards: Card[] = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  title: `Card ${i + 1}`,
}));

const AnimatedCard = () => {
  const [selectedCards, setSelectedCards] = useState<(Card | null)[]>([
    null,
    null,
    null,
  ]);
  const positionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const [movingCard, setMovingCard] = useState<Card | null>(null);
  const [startPosition, setStartPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [intermediatePosition, setIntermediatePosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [finalPosition, setFinalPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleCardSelect = (card: Card, event: React.MouseEvent) => {
    if (selectedCards.every((slot) => slot !== null)) return; // All positions filled

    const emptySlotIndex = selectedCards.findIndex((slot) => slot === null);
    const targetRef = positionRefs[emptySlotIndex].current;

    if (targetRef) {
      const rect = targetRef.getBoundingClientRect();
      const cardRect = (event.target as HTMLElement).getBoundingClientRect();

      setStartPosition({
        x: cardRect.left,
        y: cardRect.top,
      });
      setIntermediatePosition({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
      setFinalPosition({
        x: rect.left,
        y: rect.top,
      });
      setMovingCard(card);

      setTimeout(() => {
        setIntermediatePosition(null);
        setTimeout(() => {
          setSelectedCards((prev) => {
            const updated = [...prev];
            updated[emptySlotIndex] = card;
            return updated;
          });
          setMovingCard(null);
          setFinalPosition(null);
        }, 1500); // Adjust this duration to match the second animation timing
      }, 1500); // Adjust this duration to match the first animation timing
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-4">
      <div className="mb-8 grid grid-cols-5 gap-4">
        {cards.map((card) => (
          <motion.div
            key={card.id}
            onClick={(e) => handleCardSelect(card, e)}
            className="shadow flex h-36 w-24 cursor-pointer items-center justify-center rounded bg-blue-500 text-white"
            whileHover={{ scale: 1.1 }}
          >
            {card.title}
          </motion.div>
        ))}
      </div>

      <div className="flex w-full max-w-md justify-around">
        {positionRefs.map((ref, index) => (
          <div
            key={index}
            ref={ref}
            className="flex h-36 w-24 items-center justify-center border-2 border-dashed border-gray-400"
          >
            {selectedCards[index] && (
              <div className="bg-green-500 flex h-full w-full items-center justify-center rounded text-white">
                {selectedCards[index]?.title}
              </div>
            )}
          </div>
        ))}
      </div>

      {movingCard && startPosition && intermediatePosition && (
        <motion.div
          className="shadow fixed flex h-36 w-24 items-center justify-center rounded bg-red-500 text-white"
          style={{
            left: startPosition.x,
            top: startPosition.y,
            transform: "translate(-50%, -50%)",
          }}
          animate={{
            x: intermediatePosition.x - startPosition.x,
            y: intermediatePosition.y - startPosition.y,
            scale: 1.5,
          }}
          transition={{ duration: 1 }}
        >
          {movingCard.title}
        </motion.div>
      )}

      {movingCard && finalPosition && !intermediatePosition && (
        <motion.div
          className="shadow fixed flex h-36 w-24 items-center justify-center rounded bg-red-500 text-white"
          style={{
            left: window.innerWidth / 2,
            top: window.innerHeight / 2,
            transform: "translate(-50%, -50%)",
            scale: 1.5,
          }}
          animate={{
            x: finalPosition.x - window.innerWidth / 2,
            y: finalPosition.y - window.innerHeight / 2,
            scale: 1,
          }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
        >
          {movingCard.title}
        </motion.div>
      )}
    </div>
  );
};

export default AnimatedCard;
