import SmallSize from "../../../assets/icons/size/small-size";
import MediumSize from "../../../assets/icons/size/medium-size";
import LargeSize from "../../../assets/icons/size/large-size";
import { s3ImageUrl } from "../../../utils/FileUtils";

const SizeItem = ({
  value,
  checked,
  handleChange,
  label,
  isOneSize = false,
}) => {
  const sizeComponentMapping = {
    small: SmallSize,
    medium: MediumSize,
    large: LargeSize,
  };

  const bottomMapping = {
    small: 44,
    medium: 48,
    large: 55,
  };

  const labelMapping = {
    small: "Nhỏ",
    medium: "Vừa",
    large: "Lớn",
    oneSize: "Một Cỡ",
  };

  const SizeComponent = sizeComponentMapping?.[value] || null;

  return (
    <div
      key={value}
      onClick={() => {
        handleChange(value);
      }}
      className={`relative mr-2 flex w-fit flex-col items-center justify-end rounded-full px-3 py-3px`}
    >
      {SizeComponent && (
        <SizeComponent color={checked ? "#9F2B2B" : "#131313"} />
      )}
      {checked && (
        <img
          style={{
            bottom: bottomMapping?.[value] || 0,
          }}
          src={s3ImageUrl("size_checked.png")}
          className={`absolute left-[24.5px] h-4 w-4 ${isOneSize ? "left-[27px]" : ""}`}
          alt=""
        />
      )}
      <span
        className={`mt-2 text-[14px] ${checked ? `text-[#9F2B2B] underline` : "text-black"}`}
      >
        {isOneSize ? labelMapping?.["oneSize"] : labelMapping?.[value]}
      </span>
    </div>
  );
};

export default SizeItem;
