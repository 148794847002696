/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthContext } from "contexts/AuthContext";
import { isEmpty } from "lodash";
import { CategoryProduct } from "models/Product";
import { useEffect } from "react";
import { useLazyQueryFavoriteQuery } from "store/api/consumerApi";
import { useGetMenuQuery } from "store/api/menuApi";
import { useAppDispatch, useAppSelector } from "store/hook";
import {
  setAllItems,
  setCategories,
  setFavorites,
  setToppings,
} from "store/itemSlice";
import { indexAllItems } from "utils/StoreUtils";

export const DEFAULT_COUNTRY = "vn";
export const DEFAULT_LOCATION = "han_1nx";
export const DEFAULT_MENU = "v1";
export const useMenuData = () => {
  const dispatch = useAppDispatch();
  const {
    data: categories = [],
    error,
    isLoading,
    isSuccess,
  } = useGetMenuQuery({
    country: DEFAULT_COUNTRY,
    location: DEFAULT_LOCATION,
    version: DEFAULT_MENU,
  });

  const [fetchFavorites, favoriteResults] = useLazyQueryFavoriteQuery();

  const { isAuthenticated: isLogin } = useAuthContext();

  useEffect(() => {
    if (isSuccess && !isEmpty(categories)) {
      dispatch(setCategories(categories));
      dispatch(setAllItems(indexAllItems(categories as CategoryProduct[])));
      dispatch(
        setToppings(
          categories.find((c: CategoryProduct) => c.type === "topping"),
        ),
      );
    }
  }, [isSuccess, categories]);

  useEffect(() => {
    isLogin &&
      fetchFavorites({
        limit: 100,
        page: 0,
      });
  }, [isLogin]);

  useEffect(() => {
    if (favoriteResults.isSuccess) {
      dispatch(setFavorites(favoriteResults.data?.result || []));
    }
  }, [favoriteResults.isSuccess]);

  return {
    categories,
    error,
    isLoading,
    isSuccess,
    favorites: favoriteResults.data?.result,
    fetchFavorites,
  };
};
