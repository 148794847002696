import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryProduct, Product } from "../models/Product";
import { Favorite } from "./type";

interface ItemState {
  allItems: Record<string, Product>;
  categories: CategoryProduct[];
  selectedItem: Product | null;
  toppings: CategoryProduct | null;
  favorites: Favorite[];
  favoritesInString: string[];
}

const initialState: ItemState = {
  allItems: {},
  categories: [],
  selectedItem: null,
  toppings: null,
  favorites: [],
  favoritesInString: [],
};

const itemSlice = createSlice({
  name: "allItems",
  initialState,
  reducers: {
    setAllItems: (state, action: PayloadAction<Record<string, Product>>) => {
      state.allItems = action.payload;
    },
    setCategories: (state, action: PayloadAction<CategoryProduct[]>) => {
      state.categories = action.payload;
    },
    setToppings: (state, action: PayloadAction<CategoryProduct>) => {
      state.toppings = action.payload;
    },
    setFavorites: (state, action: PayloadAction<Favorite[]>) => {
      state.favorites = action.payload;
      state.favoritesInString = action.payload
        .map((item) => {
          return item.item_id;
        })
        .filter((item) => item !== "");
    },
  },
});

export const { setAllItems, setCategories, setToppings, setFavorites } =
  itemSlice.actions;
export default itemSlice.reducer;
