import { useAppSelector } from "store/hook";
import { useLanguage } from "../../hooks/useLanguage";
import { LOCAL_STORAGE_KEYS } from "helpers/constants";

export interface TableSectionProps {
  className?: string;
}

export default function TableSection(props: TableSectionProps) {
  const tableId = localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_ID);
  const { t } = useLanguage();
  const tableInfo = tableId
    ? {
        title: `${t("table")} ${tableId}`,
        description: t("scanQrCodeToChangeTable"),
      }
    : {
        title: t("tableNull"),
        description: t("scanQrCodeToOrder"),
      };
  return (
    <div
      className={`flex flex-row items-center justify-between rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <div>
        <p
          className={`text-16px font-bold text-brand-4`}
        >{`${tableInfo.title}`}</p>
        <p className={`text-12px font-normal text-tertiary`}>
          {`${tableInfo.description}`}
        </p>
      </div>
      {/* <div>
      <button className={`bg-brand-1 px-8 py-[9px] rounded-[40px]`}>
        <p className={`text-14px font-semibold text-white`}>{t('changeTable')}</p>
      </button>
    </div> */}
    </div>
  );
}
