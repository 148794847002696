import { Carousel, useCarousel } from "nuka-carousel";
import { s3ImageUrl } from "../../utils/FileUtils";

export const CustomDots = () => {
  const { totalPages, currentPage, goToPage } = useCarousel();
  return (
    <div className="flex items-center flex-row justify-center w-full py-2 gap-1">
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          style={{
            backgroundColor: currentPage === index ? "#9F2B2B" : "#ECA6AC",
            width: currentPage === index ? 24 : 6
          }}
          onClick={() => goToPage(index)}
          className={`w-[6px] h-[6px] p-0 rounded-full bg-gray-200 border-none cursor-pointer hover:bg-green-200 
         `}
        />
      ))}
    </div>
  );
};

export default function BannerSection() {
  return <div>
    <Carousel scrollDistance="slide" showDots dots={<CustomDots />} autoplay wrapMode={'wrap'}>
      <img src={s3ImageUrl("san_home_banner_1.webp")} className={`px-4`} alt="banner" />
      <img src={s3ImageUrl("san_home_banner_1.webp")} className={`px-4`} alt="banner" />
      <img src={s3ImageUrl("san_home_banner_1.webp")} className={`px-4`} alt="banner" />
    </Carousel>
  </div>;
}
