import HomeHeader from "./header";
import { CartDrawer } from "containers/drawer/CartDrawer";
import React from "react";
import { s3ImageUrl } from "../../utils/FileUtils";

const Layout = (props) => (
  <main
    className={`relative min-h-screen w-screen max-w-screen-md flex-grow`}
    style={{
      minHeight: "-webkit-fill-available",
      WebkitOverflowScrolling: "touch",
      ...props.style,
    }}
  >
    <img
      src={s3ImageUrl("san_home_bg_2.webp")}
      alt=""
      className={`z-0 w-full `}
    />
    <div className={`absolute left-0 right-0 top-0 z-0`}>
      <HomeHeader />
      <div
        style={{
          backgroundImage: s3ImageUrl("san_home_bg.png"),
        }}
        className="flex h-full min-h-screen w-full flex-grow flex-col"
      >
        <div className="flex-auto pb-50px pt-90px md:px-35px">
          {props.children}
        </div>
      </div>
    </div>
  </main>
);

export default Layout;
