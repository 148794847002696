import ChevronRight from "assets/icons/chevron-right";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export type GeneralMenuItemProps = {
  menu: GeneralMenuItem;
  onClick?: () => void;
  index: number;
  total: number;
};

export type GeneralMenuItem = {
  id: number;
  name: string;
  path: string;
  icon: string;
  subMenus?: GeneralMenuItem[];
  label?: string;
};

export function GeneralMenuItem(props: GeneralMenuItemProps) {
  const { menu, onClick, index, total } = props;
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  return (
    <a
      href={menu.path}
      onClick={(e) => {
        e.preventDefault();
        if (menu?.subMenus) {
          setShowSubMenu(!showSubMenu);
        } else {
          navigate(menu.path);
        }
      }}
      key={`profile-${menu.id}-${index}`}
      className={`flex cursor-pointer flex-col items-start justify-between py-3 ${index < total ? "border-b-[1px]" : ""} border-solid border-border-1`}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex w-full flex-row items-center justify-between">
          {/* <img
        src={s3ImageUrl(menu.icon)}
        alt=""
        className="h-[24px] w-[24px]"
        /> */}
          <p className="ml-0 text-14px font-normal text-brand-4">{menu.name}</p>
          <p className="ml-0 text-14px font-normal text-brand-4">
            {menu?.label}
          </p>
        </div>
        {!menu?.label && (
          <div
            className={`duration-400 flex items-center justify-center transition-transform ease-in-out ${showSubMenu ? "rotate-90 transform" : ""}`}
          >
            <ChevronRight color="#9F2B2B" />
          </div>
        )}
      </div>

      {menu?.subMenus && (
        <div
          className={`duration-400 mt-1 w-full transition-transform ease-in-out ${showSubMenu ? "block" : "hidden"}`}
        >
          {menu.subMenus.map((subMenu) => (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(subMenu.path);
              }}
              className="w-full py-1"
              key={`general-${subMenu.id}`}
            >
              <p>{subMenu.name}</p>
            </div>
          ))}
        </div>
      )}
      {/* <ChevronRight color="#9F2B2B" /> */}
    </a>
  );
}
