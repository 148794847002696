import CheckBoxIcon from "assets/icons/check-box";
import { Gap } from "components/gap/Gap";
import { useLanguage } from "hooks/useLanguage";
import { Product, SelectedTopping } from "models/Product";
import { formatCurrency } from "utils/ConcurencyUtils";

type ToppingTabbarProps = {
  toppings?: Product[];
  selectedToppings: SelectedTopping[];
  setSelectedToppings: (toppings: SelectedTopping[]) => void;
  className?: string;
};

export default function ToppingTabbar(props: ToppingTabbarProps) {
  const { toppings = [], className = "" } = props;
  const { language } = useLanguage();
  return (
    <div className={`overflow-scroll ${className}`}>
      <Gap size={"S"} />
      <div className="grid grid-cols-2 gap-2">
        {toppings?.map((topping) => {
          const isSelected = props.selectedToppings?.find(
            (item) => item.id === topping.id,
          );
          const mediumSize = topping?.pricing?.find(
            (item) => item.size === "medium",
          );

          const onItemClick = () => {
            if (isSelected) {
              props.setSelectedToppings?.(
                props.selectedToppings.filter((item) => item.id !== topping.id),
              );
            } else {
              props.setSelectedToppings?.([
                ...props.selectedToppings,
                {
                  id: topping.id,
                  name: topping.name?.[language],
                  price: mediumSize?.value || 0,
                  member_price: mediumSize?.member_value || 0,
                  size: "medium",
                },
              ]);
            }
          };

          return (
            <div
              key={topping.id}
              onClick={onItemClick}
              className="h-[196px] rounded-lg border-[1px] border-solid border-border-1 p-2"
            >
              <div className="flex h-[184px] flex-col justify-between gap-2">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-12px font-normal">
                    {`+${formatCurrency(mediumSize?.value || 0)}`}
                  </p>
                  <CheckBoxIcon selected={isSelected} />
                </div>
                <p className="line-clamp-1 text-12px font-normal">
                  {topping.name?.[language]}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
