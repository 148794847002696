import { useLanguage } from "hooks/useLanguage";

export const useProfileItem = () => {
  const { t } = useLanguage();

  const profileMenus = [
    {
      id: 1,
      name: t("favorite"),
      path: "/favorite",
      icon: "profile_favourite.png",
      shortName: t("favorite"),
    },
    // {
    //   id: 2,
    //   name: t("discount"),
    //   path: "/discount",
    //   icon: "profile_voucher.png",
    //   shortName: t("promotion"),
    // },
    // {
    //   id: 3,
    //   name: t("gift"),
    //   path: "/gift",
    //   icon: "profile_gift.png",
    //   shortName: t("gift"),
    // },
    {
      id: 4,
      name: t("orderHistory"),
      path: "/order-history",
      icon: "profile_history.png",
      shortName: t("order"),
    },
    // {
    //   id: 5,
    //   name: t("store"),
    //   path: "/store",
    //   icon: "profile_store.png",
    //   shortName: t("store"),
    // },
  ];

  const generalMenus = [
    {
      id: 2,
      name: t("policy"),
      path: "/policy",
      icon: "profile_setting.png",
      subMenus: [
        {
          id: 1,
          name: t("securePolicy"),
          path: "/privacy-policy",
        },
        {
          id: 3,
          name: t("returnPolicy"),
          path: "/return-policy",
        },
        {
          id: 4,
          name: t("shippingPolicy"),
          path: "/shipping-policy",
        },
      ],
    },
    {
      id: 3,
      name: t("vnpayGuide"),
      path: "/vnpay-guide",
      icon: "profile_setting.png",
    },
    {
      id: 4,
      name: t("aboutUs"),
      path: "/about",
      icon: "profile_setting.png",
    },
    {
      id: 1,
      name: t("language"),
      // path: "/language",
      icon: "profile_setting.png",
      label: "Tiếng Việt",
    },
  ];

  return {
    profileMenus,
    generalMenus,
  };
};
