import * as React from "react";
const NoIceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={70}
    viewBox="0 0 42 70"
    fill="none"
    {...props}
  >
    <path
      d="M28.3276 68.9938H13.6723C10.8979 68.9938 8.52341 67.0026 8.04851 64.2615L3.04122 25.2946H38.9587L33.9514 64.2615C33.4765 66.9942 31.102 68.9938 28.3276 68.9938Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M39.242 18.4961H2.75797C1.78707 18.4961 1 19.2832 1 20.2541V23.5367C1 24.5076 1.78707 25.2947 2.75797 25.2947H39.242C40.2129 25.2947 41 24.5076 41 23.5367V20.2541C41 19.2832 40.2129 18.4961 39.242 18.4961Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M20.9958 1.61621H19.9127C10.598 1.61621 3.04122 9.17297 3.04122 18.496H38.9587C38.9587 9.17297 31.402 1.61621 22.0789 1.61621H20.9958Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
export default NoIceIcon;
