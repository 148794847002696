import { useAuthContext } from "contexts/AuthContext";
import { useState } from "react";
import {
  useStartAnonymousExperienceMutation,
  useStartExperienceMutation,
} from "store/api/consumerApi";
import { useAppSelector } from "store/hook";

export type UsePlayTarot = {
  topicType: string;
  context: string;
};

export const usePlayTarot = (props: UsePlayTarot) => {
  const { topicType, context } = props;
  const { isAuthenticated: isLogin } = useAuthContext();
  const sessionId = useAppSelector((root) => root.session.session_id);
  const expSessionId = useAppSelector((root) => root.session.exp_session_id);
  const [startExperience, { isLoading }] = useStartExperienceMutation();
  const [startAnonymousExperience, { isLoading: isLoadingAnonymous }] =
    useStartAnonymousExperienceMutation();

  const [tarotResult, setTarotResult] = useState<any>();

  const playTarot = async () => {
    if (!isLogin) {
      const res = await startAnonymousExperience({
        session_id: sessionId || "",
        exp_session_id: expSessionId,
      });
      setTarotResult(res);
      return res?.data;
    } else {
      const res = await startExperience({
        session_id: sessionId || "",
        exp_session_id: expSessionId,
        aspect: topicType,
        context,
      });
      setTarotResult(res);
      return res?.data;
    }
  };

  return {
    playTarot,
    tarotResult: tarotResult?.data,
    isLoading: isLoading || isLoadingAnonymous,
  };
};
