import * as React from "react";
const FullIceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={70}
    viewBox="0 0 42 70"
    fill="none"
    {...props}
  >
    <path
      d="M28.3276 68.3777H13.6723C10.8979 68.3777 8.52339 66.3865 8.04849 63.6454L3.0412 24.6785H38.9587L33.9514 63.6454C33.4765 66.3781 31.102 68.3777 28.3276 68.3777Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M39.242 17.8799H2.75797C1.78707 17.8799 1 18.667 1 19.6379V22.9205C1 23.8914 1.78707 24.6785 2.75797 24.6785H39.242C40.2129 24.6785 41 23.8914 41 22.9205V19.6379C41 18.667 40.2129 17.8799 39.242 17.8799Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M20.9958 1H19.9127C10.598 1 3.0412 8.55676 3.0412 17.8798H38.9587C38.9587 8.55676 31.402 1 22.0789 1H20.9958Z"
      stroke={props.color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <rect
      x={9}
      y={61.5215}
      width={9}
      height={9}
      rx={1}
      transform="rotate(-37.8421 9 61.5215)"
      stroke={props.color}
      strokeWidth={2}
    />
    <rect
      x={25.2009}
      y={54}
      width={9}
      height={9}
      rx={1}
      transform="rotate(35.3015 25.2009 54)"
      stroke={props.color}
      strokeWidth={2}
    />
    <rect
      x={25.7048}
      y={39.7354}
      width={9}
      height={9}
      rx={1}
      transform="rotate(138.45 25.7048 39.7354)"
      stroke={props.color}
      strokeWidth={2}
    />
    <path
      d="M5.95115 48.0375L13.1389 44.5253C13.6352 44.2828 13.8409 43.684 13.5984 43.1878L10.5262 36.9006C10.2834 36.4035 9.68311 36.1981 9.18664 36.4421L4.99992 38.5"
      stroke={props.color}
      strokeWidth={2}
    />
    <rect
      x={20}
      y={44.9563}
      width={9}
      height={9}
      rx={1}
      transform="rotate(-6.0993 20 44.9563)"
      stroke={props.color}
      strokeWidth={2}
    />
    <path
      d="M36.0156 47L30.6683 52.9503C30.2991 53.3611 30.3329 53.9934 30.7437 54.3625L34.4999 57.7381"
      stroke={props.color}
      strokeWidth={2}
    />
    <rect
      x={13.3044}
      y={45}
      width={9}
      height={9}
      rx={1}
      transform="rotate(44.4669 13.3044 45)"
      stroke={props.color}
      strokeWidth={2}
    />
    <rect
      x={35.1494}
      y={35.1509}
      width={9}
      height={9}
      rx={1}
      transform="rotate(90.9597 35.1494 35.1509)"
      stroke={props.color}
      strokeWidth={2}
    />
    <path
      d="M21.6437 24.4999L18.5014 29.9213C18.2244 30.3991 18.3873 31.011 18.8651 31.2879L24.9213 34.7982C25.3991 35.0752 26.011 34.9124 26.288 34.4345L29.809 28.3598C30.0817 27.8893 29.9284 27.287 29.4639 27.0042L25.7865 24.765"
      stroke={props.color}
      strokeWidth={2}
    />
    <rect
      x={6}
      y={28.2717}
      width={9}
      height={9}
      rx={1}
      transform="rotate(-21.3171 6 28.2717)"
      stroke={props.color}
      strokeWidth={2}
    />
    <path
      d="M38.5 27.5L32.2007 26.6601C31.578 26.5771 31.0338 27.0821 31.0702 27.7093L31.3431 32.4064C31.3748 32.9525 31.8394 33.3718 32.3859 33.3474L38.0001 33.0975"
      stroke={props.color}
      strokeWidth={2}
    />
  </svg>
);
export default FullIceIcon;
