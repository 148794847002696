import { Gap } from "components/gap/Gap";
import SanButton from "components/SanButton";
import { useLanguage } from "hooks/useLanguage";
import { s3ImageUrl } from "utils/FileUtils";
import { LoginButton } from "./LoginButton";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader";
import { useEffect, useState } from "react";
import { usePasswordlessLogin } from "./usePasswordlessLogin";
import { withSnackbar, WithSnackbarProps } from "hoc/withSnackbar";
import { useAppDispatch } from "store/hook";
import { setStInfo } from "store/authSlice";
import { useHandleLogin } from "hooks/useHandleLogin";

type LoginPageProps = {};

function LoginPage(props: LoginPageProps & WithSnackbarProps) {
  const { showSnackbar = () => null } = props;
  const { t } = useLanguage();
  const { state } = useLocation();
  const { source = "" } = state || {};
  const [usePhoneNumber, setUsePhoneNumber] = useState(true);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const { login } = useHandleLogin({
    showSnackbar,
    setIsLoading,
    usePhoneNumber,
    source,
  });

  useEffect(() => {
    if (usePhoneNumber) {
      setDisabledLogin(emailOrPhone.length < 9);
    } else {
      setDisabledLogin(!emailOrPhone.includes("@"));
    }
  }, [emailOrPhone]);

  useEffect(() => {
    setEmailOrPhone("");
  }, [usePhoneNumber]);

  const socialLoginButtons = [
    {
      title: t("continueWithGoogle"),
      type: "google",
      logo: "login_gg.png",
    },
    {
      title: t("continueWithFacebook"),
      type: "facebook",
      logo: "login_fb.png",
    },
    {
      title: t("continueWithApple"),
      type: "apple",
      logo: "login_apple.png",
    },
  ];

  return (
    <main
      className={`flex h-screen w-full flex-col items-center overflow-hidden`}
      style={{
        minHeight: "-webkit-fill-available",
        WebkitOverflowScrolling: "touch",
      }}
    >
      <div className="relative w-full max-w-screen-md">
        <img
          src={s3ImageUrl("bg_san_no_logo.webp")}
          alt=""
          className={`z-0 w-full`}
        />
        <div className="absolute bottom-0 left-0 right-0 top-0 z-0">
          <PageHeader transparent />
          <div
            className={`absolute left-4 right-4 top-20 z-0 rounded-2xl bg-white md:left-8 md:right-8 md:top-40`}
          >
            <div className="flex h-full min-h-screen w-full flex-grow flex-col items-center px-4 pt-6">
              <img
                src={s3ImageUrl("san-logo.png")}
                alt=""
                className="h-12 w-12"
              />
              <Gap size={"S"} />
              <p className="text-18px font-medium text-brand-4">
                {t("welcomeToSan")}
              </p>
              <Gap size={"M1"} />
              <div className="relative w-full">
                {usePhoneNumber && (
                  <div className="absolute bottom-0 left-0 top-0 flex flex-row items-center justify-between gap-1.5 pl-4">
                    <img
                      src={s3ImageUrl("san_flag_vn.png")}
                      className="h-4 w-4"
                      alt=""
                    />
                    <p>+84</p>
                    <div className="h-[20px] w-[1px] bg-border-1" />
                  </div>
                )}
                <input
                  value={emailOrPhone}
                  onChange={(e) => {
                    setEmailOrPhone(e.target.value);
                  }}
                  type={usePhoneNumber ? "tel" : "email"}
                  placeholder={
                    usePhoneNumber ? t("enterPhoneNumber") : t("enterEmail")
                  }
                  className={`h-12 w-full rounded-[40px] border-[1px] border-solid border-border-1 ${usePhoneNumber ? "pl-[74px]" : "pl-4"} focus:border-brand-1 focus:outline-none focus:ring-0`}
                />
              </div>
              <div
                onClick={() => {
                  setUsePhoneNumber(!usePhoneNumber);
                }}
                className="flex w-full cursor-pointer justify-end pb-3 pr-2"
              >
                <p className="mt-2 text-left text-12px font-semibold text-brand">
                  {usePhoneNumber ? t("useEmail") : t("usePhoneNumber")}
                </p>
              </div>
              <SanButton
                loading={isLoading}
                title={t("login")}
                type={disabledLogin ? "disabled" : "solid"}
                disabled={disabledLogin}
                onClick={async () => {
                  setIsLoading(true);
                  login(emailOrPhone);
                }}
              />
              {/* <Gap size={"S"} />
            <div className="relative flex w-full flex-row items-center justify-center py-4">
              <div className="absolute left-0 right-0 top-1 flex flex-row items-center justify-center">
                <p className="bg-white px-2 text-14px font-normal text-tertiary">
                  {t("or")}
                </p>
              </div>
              <div className="h-[1px] w-full bg-border-1" />
            </div>
            <Gap size={"S"} />
            <LoginButton
              onClick={() => {
                setUsePhoneNumber(!usePhoneNumber);
              }}
              title={
                usePhoneNumber ? t("loginViaEmail") : t("loginViaPhoneNumber")
              }
            /> */}
              <Gap size={"S"} />
              {/* <div className="flex w-full flex-col gap-2">
              {socialLoginButtons.map((item, index) => {
                return (
                  <LoginButton
                    key={index}
                    onClick={() => {}}
                    logo={item.logo}
                    title={item.title}
                    type={item.type as "google" | "apple" | "facebook"}
                  />
                );
              })}
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withSnackbar(LoginPage);
