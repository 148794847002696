import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { useLanguage } from "hooks/useLanguage";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hook";
import { s3ImageUrl } from "utils/FileUtils";
import WaveDivider from "./components/WaveDivider";
import TarotCarousel from "./components/TarotCarousel";
import { useEffect, useRef, useState } from "react";
import TarotCard from "./components/TarotCard";
import SanButton from "components/SanButton";
import CloseIcon from "assets/icons/close";
import { removeExpSessionId } from "store/sessionSlice";
import { useTarotTopics } from "./hooks/useTarotTopics";
import { usePlayTarot } from "./hooks/usePlayTarot";
import { withSnackbar, WithSnackbarProps } from "hoc/withSnackbar";
import { motion } from "framer-motion";
import TarotGuide from "./components/TarotGuide";

export type TarotExperienceProps = {};

function TarotExperience(props: TarotExperienceProps & WithSnackbarProps) {
  const { showSnackbar = () => null } = props;
  const { t } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { topicType = "general", context = "" } = state || {};

  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const { topics } = useTarotTopics();

  const {
    playTarot,
    isLoading: isPlayingTarot,
    tarotResult,
  } = usePlayTarot({
    topicType,
    context,
  });

  useEffect(() => {
    playTarot();
  }, []);

  const [selectedCards, setSelectedCards] = useState<number[]>([0, 0, 0]);

  const totalSelectedCards = selectedCards.reduce((acc, current) => {
    return acc + current;
  }, 0);

  const selectedTopic = topicType
    ? topics[topicType as string]
    : topics.general;

  const [waitingForResult, setWaitingForResult] = useState(false);

  const nextCardIndex = selectedCards.findIndex((card) => card === 0);

  useEffect(() => {
    if (waitingForResult && !isPlayingTarot) {
      setIsLoadingResult(false);
      setWaitingForResult(false);
      if (tarotResult) {
        navigate("/tarot-result", {
          state: {
            tarotResult,
          },
        });
      } else {
        showSnackbar({
          message: t("somethingWentWrong"),
        });
      }
    }
  }, [waitingForResult, isPlayingTarot, tarotResult]);

  const openTarotResult = async () => {
    // If tarot is still playing, set waitingForResult to true
    if (isPlayingTarot) {
      console.log("isPlayingTarot");
      setIsLoadingResult(true);
      setWaitingForResult(true);
      return;
    } else {
      // If tarot is done playing, wait for 2.5 seconds before navigate to tarot result
      if (tarotResult) {
        console.log("tarot done", tarotResult);
        setIsLoadingResult(true);
        setTimeout(() => {
          setIsLoadingResult(false);
          navigate("/tarot-result", {
            state: {
              tarotResult,
            },
          });
        }, 2500);
      } else {
        // If tarot is done playing but there is no result, play tarot again
        console.log("tarot done BUT no result, try again", tarotResult);
        setIsLoadingResult(true);
        const res = await playTarot();
        if (res) {
          setIsLoadingResult(false);
          navigate("/tarot-result", {
            state: {
              tarotResult: res,
            },
          });
        } else {
          showSnackbar({
            message: t("somethingWentWrong"),
          });
        }
        setIsLoadingResult(false);
      }
    }
  };

  const selectedCardTitleByIndex = {
    0: t("present"),
    1: t("future"),
    2: t("advice"),
  };

  // Card animation

  const positionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const [movingCard, setMovingCard] = useState<any | null>(null);
  const [startPosition, setStartPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [intermediatePosition, setIntermediatePosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [finalPosition, setFinalPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleCardSelect = (card: any, event: React.MouseEvent) => {
    const emptySlotIndex = selectedCards.findIndex((slot) => slot === 0);
    if (emptySlotIndex === -1) return;
    const targetRef = positionRefs[emptySlotIndex].current;

    if (targetRef) {
      const rect = targetRef.getBoundingClientRect();
      const cardRect = (event.target as HTMLElement).getBoundingClientRect();

      setStartPosition({
        x: cardRect.left,
        y: cardRect.top,
      });
      setIntermediatePosition({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
      setFinalPosition({
        x: rect.left + 45.5,
        y: rect.top,
      });
      setMovingCard(card);

      setTimeout(() => {
        setIntermediatePosition(null);
        setTimeout(() => {
          setSelectedCards((prev) => {
            const updated = [...prev];
            updated[emptySlotIndex] = 1;
            return updated;
          });
          setMovingCard(null);
          setFinalPosition(null);
        }, 400); // Adjust this duration to match the second animation timing
      }, 400); // Adjust this duration to match the first animation timing
    }
  };

  return (
    <div
      style={{ backgroundColor: selectedTopic.bgColor, height: "100vh" }}
      className="flex flex-col items-center bg-[#fce8ce] sm:h-full"
    >
      {/* Section 1 */}
      <div className="relative flex w-full flex-col items-center">
        <PageHeader
          IconLeft={() => (
            <div>
              <div
                onClick={() => {
                  navigate("/");
                  dispatch(removeExpSessionId());
                }}
                className="flex h-9 w-9 cursor-pointer items-center justify-center"
              >
                <CloseIcon
                  color={topicType === "general" ? "#31261D" : "#FFF"}
                />
              </div>
            </div>
          )}
          transparent={true}
        />
        <section
          className={`z-0 flex h-[80px] w-full max-w-screen-md flex-col items-center
             overflow-visible transition-all duration-200 ease-in-out sm:h-[180px] md:h-[240px]
             `}
        >
          <img
            src={selectedTopic.image}
            alt=""
            className={`-mt-16 h-auto w-7/12 sm:-mt-16 ${selectedTopic.scale} ${selectedTopic.firstMarginTop} cursor-pointer object-contain transition-all duration-200 ease-in-out `}
          />
          <Gap size="S" />
        </section>
        <WaveDivider />
      </div>

      {/* Section 2 */}
      <section className="z-10 -mt-[1px] flex h-full w-full flex-col items-center justify-center bg-[#fce8ce]">
        <div className="flex h-full w-full max-w-screen-md flex-col items-center">
          <span
            style={{ color: selectedTopic.bgColor }}
            className={`mt-5 font-space text-[36px] font-bold leading-[40px] md:text-[48px] md:leading-[56px]`}
          >
            {selectedTopic.title}
          </span>
          <Gap size="S" />
          <div className="sm:mt-4">
            {/* Render selected card */}
            <div className="flex flex-row gap-4 sm:gap-6">
              {positionRefs.map((ref, index) => {
                return (
                  <div
                    ref={ref}
                    key={index}
                    className="flex h-[160px] flex-col items-center justify-between md:h-[250px] md:w-[150px] xl:min-h-[200px]"
                  >
                    {selectedCards?.[index] ? (
                      <TarotCard
                        className="min-h-[140px] w-[83px] sm:h-[170px] sm:w-[100px] md:h-[230px] md:w-[140px] xl:h-[170px] xl:w-[102px]"
                        key={`selected-card-${index}`}
                        onCardClick={() => {
                          const newSelectedCards = [...selectedCards];
                          newSelectedCards[index] = 0;
                          setSelectedCards(newSelectedCards);
                        }}
                      />
                    ) : (
                      <div
                        className={`
                        min-h-[140px] w-[81px] rounded-lg  border-dashed sm:mt-0 sm:h-[170px] sm:min-h-[170px] sm:w-[100px] sm:min-w-[102px] md:mt-0 md:min-h-[230px] md:w-[140px]
                        ${nextCardIndex === index ? "border-[2px] border-brand-1" : "border-[2px] border-brand-31"}
                        `}
                      ></div>
                    )}
                    <span
                      className={`mt-2 font-space text-12px ${nextCardIndex === index ? "font-bold text-brand-1" : "font-semibold text-brand-31"} md:mt-4 md:text-[20px]`}
                    >
                      {selectedCardTitleByIndex[index]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <Gap size="M" />
          <Gap size="S" />
          {totalSelectedCards < 3 ? (
            <span className="font-inter text-12px font-normal text-brand-4 sm:mt-3 md:text-[18px]">
              {`${t("selectCard").replace("{numOfCards}", `${3 - totalSelectedCards}`)}`}
            </span>
          ) : (
            <span className="font-inter text-12px font-normal text-brand-4 sm:mt-3 md:text-[18px]">
              {`${t("youCanClickToAnyCardToSelectAgain")}`}
            </span>
          )}
          <div className="flex w-full max-w-screen-md flex-col items-center sm:pl-0">
            <TarotCarousel
              onCardClick={(e) => {
                handleCardSelect(e, e);
              }}
            />
          </div>

          {/* Open Tarot Result */}
          <div className="-mt-2 flex w-full flex-col items-center px-4">
            {totalSelectedCards === 3 ? (
              <SanButton
                onClick={openTarotResult}
                loading={isLoadingResult}
                title={t("tarotResult")}
              />
            ) : (
              <TarotGuide />
            )}
          </div>
          <Gap size="S" />
        </div>
      </section>
      {movingCard && startPosition && intermediatePosition && (
        <motion.div
          className="shadow fixed z-50 flex h-36 w-24 items-center justify-center rounded bg-red-500 text-white sm:h-[170px] sm:w-[100px] md:h-[250px] md:w-[150px]"
          style={{
            left: startPosition.x,
            top: startPosition.y,
          }}
          animate={{
            x: intermediatePosition.x - startPosition.x - 45.5,
            y: intermediatePosition.y - startPosition.y,
            scale: 1.3,
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <TarotCard onCardClick={() => null} />
        </motion.div>
      )}

      {movingCard && finalPosition && !intermediatePosition && (
        <motion.div
          className="shadow fixed z-50 flex h-36 w-24 items-center justify-center rounded bg-red-500 text-white sm:h-[170px] sm:w-[100px] md:h-[250px] md:w-[150px]"
          style={{
            left: window.innerWidth / 2 - 45.5,
            top: window.innerHeight / 2,
            transform: "translate(-50%, -50%)",
            scale: 1.3,
          }}
          animate={{
            x: finalPosition.x - window.innerWidth / 2 - 3,
            y: finalPosition.y - window.innerHeight / 2,
            scale: 1,
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <TarotCard onCardClick={() => null} />
        </motion.div>
      )}
    </div>
  );
}
export default withSnackbar(TarotExperience);
